<template>
    <div class="wrapper">
        <div class="consulting-descr-wrapper">
            <div class="content">
                <h3>{{ $t("pooBear.businessConsulting.title") }}</h3>
                <p>{{ $t("pooBear.businessConsulting.text") }}</p>
            </div>
            <div class="image">
                <imageFrame :shadowTop="5" :shadowLeft="7" class="pic-frame" url="images/poo/20190906_044958-min.jpg" />
            </div>
        </div>
        <h3 class="options-title">{{ $t("pooBear.businessConsulting.options.title") }}</h3>
        <div class="brand-options">
            <div class="option">
                <div class="view-option">
                    <div class="view-option-numbers">{{ $t("pooBear.businessConsulting.options.firstOption.title")  }}</div>
                    <div class="view-option-title">{{ $t("pooBear.businessConsulting.options.firstOption.text") }}</div>
                </div>
            </div>
            <div class="option">
                <div class="view-option">
                    <div class="view-option-numbers">{{ $t("pooBear.businessConsulting.options.thirdOption.title") }}</div>
                    <div class="view-option-title">{{ $t("pooBear.businessConsulting.options.thirdOption.text") }}</div>
                </div>
            </div>
            <div class="option">
                <div class="view-option">
                    <div class="view-option-numbers">{{ $t("pooBear.businessConsulting.options.secondOption.title") }}</div>
                    <div class="view-option-title">{{ $t("pooBear.businessConsulting.options.secondOption.text") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import imageFrame from './../../components/shadowed-pic'
export default {
    components: {
        imageFrame
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.wrapper {
    padding: 5vw 10vw;
}

.consulting-descr-wrapper {
    background-color: #fff;
    display: flex;
}

.content {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
        width:100%;
        font-family: $family-bold;
        margin-bottom: 0.8em;
        font-size: 2.2vw;
        letter-spacing: 0.015em;
        line-height: 1em;
        font-weight: 400;
        color:#1D1F1F;
        font-weight: 600;
    }

    p {
        font-family: $family-light;
        color: #313333;
        font-size: 1.4vw;
        line-height: 1.56em;
    }
}

.image {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 45%;
    right: -5%;

    img {
        width: 80%;
    }
}

.options-title {
    text-align: center;
    padding: 10vh 0 5vh;
    margin-bottom: 0.8em;
    @include font-size(21px);
    font-size: calc(21px + 3 * ((100vw - 375px) / 649));
    font-family: $family-bold;
    font-weight: 600;
}

.brand-options {
    display: flex;
    justify-content: space-between;
}

.option {
    width: 32%;

    h4 {
        margin-bottom: 0.5em;
        @include font-size(19px);
        font-size: calc(19px + 3 * ((100vw - 375px) / 649));
        font-family: $family-bold;
    }

    p {
        @include font-size(12px);
        font-size: calc(12px + 3 * ((100vw - 375px) / 649));
        font-family: $family-light;
    }
}

.view-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    widows: 100%;
    background-color: #F6BE45;
    margin-bottom: 40px;
    margin-bottom: 2.5vh;
    color: #fff;
    padding: 2vw;
}

.option:nth-child(2) .view-option {
    background-color: #E78549;
}

.option:nth-child(3) .view-option {
    background-color: #D64472;
}

.view-option-numbers {
    position: relative;
    @include font-size(30px);
    font-size: 2vw;
    padding-bottom: 1vw;
    margin-bottom: 1vw;

    &::before {
        content: '';
        position: absolute;
        bottom: 4px;
        height: 3px;
        width: 30px;
        background: #EFA13F;
    }
}

.view-option-title {
    @include font-size(23px);
    font-size: 1.1vw;
    font-family: $family-light;
}

@media (max-width: 992px) {

    .consulting-descr-wrapper {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        height: auto;
    } 

    .content {
        width: 100%;
        padding-bottom: 5vw;

        h3 {
            margin-bottom: 0.8em;
            @include font-size(23px);
            font-size: calc(23px + 4 * ((100vw - 375px) / 649));
        }

        p {
            font-family: $family-light;
            color: #313333;
            font-size: 1.4vw;
            line-height: 1.56em;
            will-change: transform;
            font-size: calc(16px + 2 * ((100vw - 375px) / 649));
        }
    }

    .image {
        width:100%;
        right:0;
    }
}

@media (max-width: 576px) {
    
    .wrapper {
        padding: 0 5vw;
    }

    .image {
        padding-top: 5vw;
        width:100%;
        height: 250px;

        img {
            margin: auto;
            width: 100%;
        }
    }

    .brand-options {
        flex-direction: column;
    }

    .option {
        width: 100%;
        padding-bottom: 10vw;
    }

    .view-option {
        padding: 5vw;
    }

    .view-option-numbers {
        position: relative;
        @include font-size(32px);
        font-size: 8vw;

        padding-bottom: 4vw;
        margin-bottom: 4vw;

        &::before {
            bottom: 3px;
        }
    }

    .view-option-title {
        @include font-size(20px);
        font-size: 4.3vw;
    }
}

</style>