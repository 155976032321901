<template>
    <div class="action-block" :class="{ ja: $i18n.locale == 'ja' }">
        <span class="question">{{ questionText }}</span>
        <router-link :to="linkHref" class="action">
            <span>{{ linkText }}</span>
            <svg width="44" height="25" viewBox="0 0 44 25" xmlns="http://www.w3.org/2000/svg"><path d="M38.5 11l-8-8L33 .5 44 12v1L33 24.5 30.5 22l8-8H0v-3h38.5z" fill="#fff" fill-rule="nonzero"></path></svg>
        </router-link>
    </div>
</template>
<script>
export default {
    props: {
        questionText: String,
        linkText: String,
        linkHref: String 
    }
}
</script>
<style lang="scss" scoped>
@import '../scss/app';

.action-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 32vh;
  background: #191a1a;

  .question {
    color: #939999;
    letter-spacing: 0.025em;
    @include font-size(15px);
    font-size: calc(16px + 5 * ((100vw - 1280px) / 640));
    line-height: calc(16px + 5 * ((100vw - 1280px) / 640));
    margin: 0 0 1.5em;
    font-family: $family-bold;
    font-weight: bold;
    display: block;
    text-align: center
  }

  .action {
    position: relative;
    color: #DCE6E6;
    font-size: 1.8vw;
    line-height: 1.8vw;
    font-family: $family-light;
    cursor: pointer;
    z-index: 0;
    padding: 10px 20px 10px;

    &::after {
      content: '';
      position: absolute;
      top:0;
      bottom:0;
      left: 0;
      right: 0;
      background: #fff;
      z-index: 1;
      background-color: #4923D8;
      @include opacity(0);
      @include transition(all 500ms);
    }

    &:hover {
      &:after {
        @include opacity(1);
      }

      svg {
        margin-left: 32px;
      }
    }

    span {
        position: relative;
        display: inline-block;
        z-index: 2;
        font-size: 1.8vw;
        font-family: $family-light;
    }

    svg {
      position: relative;
      width: 25px;
      //top: 6px;
      margin-left: 12px;
      display: inline-block;
      z-index: 2;
      @include transition(all 500ms);
    }
  }

  &.ja {

    .action {
      svg {
        position: relative;
        width: 25px;
        top: -2px;
      }
      span {
        font-size: 1.6vw;
      }
    }
  }
}

@media (max-width: 992px) {
  .action-block { 
    height: 25vh;
    .question {
          @include font-size(15px);
    }

    .action {
      span {
        @include font-size(18px);
      }
    }
    &.ja .action {
      span {
        @include font-size(18px);
        bottom: -5px;
      }
      svg {
        margin-top: 5px;
      }
    }
  }
}
</style>