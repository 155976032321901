<template>
  <div class="wrapper">
    <div class="pb-text-wrapper">
      <div class="content">
        <h3>{{ $t("pooBear.specialParties.title") }}</h3>
        <p>{{ $t("pooBear.specialParties.text") }}</p>
      </div>
      <div class="image" @mouseleave="changePictureIndex(0)">
        <div
          @mouseover="changePictureIndex(1)"
          class="image-block"
          :class="{
              'active': activeIndex == 1,
              'shrink': activeIndex != 0 && activeIndex != 1
          }"
        >
            <div class="image-block-inner" style="background-image: url('images/poo/20190902_163134.jpg')">
              <div class="image-block-text">{{ $t('pooBear.specialParties.images.title1') }}</div>
            </div>
        </div>
        <div
          @mouseover="changePictureIndex(2)"
          class="image-block"
          :class="{
              'active': activeIndex == 2,
              'shrink': activeIndex != 0 && activeIndex != 2
          }"
        >
            <div class="image-block-inner" style="background-image: url('images/poo/20190901_155850.jpg')">
              <div class="image-block-text">{{ $t('pooBear.specialParties.images.title2') }}</div>
            </div>
        </div>
        <div
          @mouseover="changePictureIndex(3)"
          class="image-block"
          :class="{
              'active': activeIndex == 3,
              'shrink': activeIndex != 0 && activeIndex != 3
          }"
        >
            <div class="image-block-inner" style="background-image: url('images/poo/20190903_234351 (4).jpg')">
              <div class="image-block-text">{{ $t('pooBear.specialParties.images.title3') }}</div>
            </div>
        </div>
        <div
          @mouseover="changePictureIndex(4)"
          class="image-block"
          :class="{
              'active': activeIndex == 4,
              'shrink': activeIndex != 0 && activeIndex != 4
          }"
        >
            <div class="image-block-inner" style="background-image: url('images/poo/20190905_152317 2.jpg')">
              <div class="image-block-text">{{ $t('pooBear.specialParties.images.title4') }}</div>
            </div>
        </div>
      </div>
    </div>
    <h2 class="title-instagram-section">{{ $t("pooBear.specialParties.instagramTitle") }}</h2>
    <div class="instagram-body">
        <iframe scrolling="no" src="./backgrounds/instagram-poo-bear.html"></iframe>
    </div>
  </div>
</template>
<script>
export default {
  data() {
      return {
          activeIndex: 1,
          mobileTimer: null,
          mobileWidth: 793,
          tabletWidth: 992
      }
  },
  methods: {
      changePictureIndex(val) {
        if(!this.isMobile() && !this.isTablet()) {
          this.activeIndex = val;
        }  
      },
      isMobile() {
        return this.getWindowWidth() <= this.mobileWidth;
      },
      isTablet() {
        return this.getWindowWidth() >= this.mobileWidth && this.getWindowWidth() <= this.tabletWidth;
      },
      getWindowWidth() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      }
  },
  destroyed() {
    clearInterval(this.mobileTimer);
  },
  mounted() {
    this.mobileTimer = setInterval(() => {
        if(this.isMobile() || this.isTablet()) {
          this.activeIndex = this.activeIndex == 4 ? 1 : this.activeIndex + 1;
        }
    }, 3000);
  }
};
</script>
<style lang="scss" scoped>
@import "./../../scss/app";

.wrapper {
  padding: 10vw;
}

.pb-text-wrapper {
  background-color: #fff;
  display: flex;
}

.content {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    width: 100%;
    font-family: $family-bold;
    margin-bottom: 0.8em;
    font-size: 2.2vw;
    letter-spacing: 0.015em;
    line-height: 1em;
    font-weight: 400;
    color: #1d1f1f;
    font-weight: 600;
  }

  p {
    font-family: $family-light;
    color: #313333;
    font-size: 1.4vw;
    line-height: 1.56em;
  }
}

.image {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 45%;
  right: -5%;
  bottom: 0;
  top: 0;
}

.image-block {
  height: 25%;
  width: 100%;
  overflow: hidden;
  @include transition(all 200ms ease);
  background: #191a1a;
}

.image-block-inner {
    position: relative;
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
    @include transition(all 300ms ease);
    overflow: hidden;
    display:flex;
    align-items: center;

    &:after {
        content: '';
        position: absolute;
        top: -10%;
        left: -10%;
        right:-10%;
        bottom: -10%;
        background-color:#191a1a;
        @include opacity(.8);
        @include transition(all 500ms ease);
        @include skew(10deg, 0);
    }
}

.image-block-text {
  display: inline-block;
  position: relative;
  top:0;
  left: 10px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  z-index: 2;
  font-family: $family-light;
  @include font-size(30px);
  font-size: 2vw;
}

.image-block.active {
    height: 40%;

    .image-block-text {
      top: -150px;
    }

    .image-block-inner {
        @include scale(1.2);
        
        &:after {
            left: -100%;
            right: 100%;
            @include opacity(.7);
        }
    }
}

.image-block.shrink {
    height: 20%;
}

.title-instagram-section {
    text-align: center;
    padding: 15vh 0 5vh;
    margin-bottom: 0.8em;
    @include font-size(21px);
    font-size: calc(21px + 3 * ((100vw - 375px) / 649));
    font-family: $family-bold;
    font-weight: 600;
}

.instagram-body {
    display: flex;
    justify-content: center;
    iframe {
        border: none;
        height: 600px;
        width: 400px;
    }
}

@media (max-width: 992px) {
  .pb-text-wrapper {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .content {
    width: 100%;
    padding-bottom: 5vw;

    h3 {
      margin-bottom: 0.8em;
      @include font-size(23px);
      font-size: calc(23px + 4 * ((100vw - 375px) / 649));
    }

    p {
      font-family: $family-light;
      color: #313333;
      font-size: 1.4vw;
      line-height: 1.56em;
      will-change: transform;
      font-size: calc(16px + 2 * ((100vw - 375px) / 649));
    }
  }

  .image {
    height: 50vh;
    width: 100%;
    right: 0;
    flex-direction: row;
  }

  .image-block {
    height: 100%;
    width: 25%;
  }

  .image-block-text {
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 3vw;
    background-color:#191a1a;
    @include opacity(.9);
    border-radius: 3px;
  }

  .image-block.active {
    height: 100%;
    width: 55%;

    .image-block-text {
      display: block;
      top: -24vw;
      left: 5vw;
    }
  }

  .image-block.shrink {
    height: 100%;
    width: 15%;
  }

  .image-block-inner {

    &::after {
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      @include skew(0, 0);
    }

    .image-block-text {
      display:none;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}

@media (max-width: 576px) {
  .wrapper {
    padding: 5vw 5vw 10vw 5vw;
  }

  .image {
    padding-top: 5vw;
    width: 100%;
    height: 250px;

    img {
      margin: auto;
      width: 100%;
    }
  }

  .instagram-body {
    iframe {
      border: none;
      height: 600px;
      width: 100%;
    }
  }

  .title-instagram-section {
    padding: 5vh 0 5vh;
  }

  .image-block.active {

    .image-block-text {
      display: block;
      top: -20vw;
      left: 5vw;
    }
  }

  .image-block-text {
    font-size: 5vw;
    background-color:#191a1a;
    @include opacity(.9);
    border-radius: 3px;
  }
}
</style>
