<template>
    <main class="wrapper-poo-bear">
        <cookies-popup />
        <navigation :isWhite="isWhiteNav"/>
        <iframe class="frame-bg" :class="{ 'frame-bg-visible': isHeroBg }" scrolling="no" src="./backgrounds/poo-bear.html"></iframe>
        <iframe class="frame-bg" :class="{ 'frame-bg-visible': isSecondStubBg }" scrolling="no" src="./backgrounds/poo-bear-party.html"></iframe>
        <div class="content-page-wrapper">
            <HEADER v-scroll="changeNavToWhite"/>
            <brief v-scroll="changeNavToBlack"/>
            <stub v-scroll="changeNavToWhite"/>
            <div v-scroll="bgTriggerSecond" class="side-menu-wrapper">
                <div class="menu-holder" v-scroll="sideMenuHandler">
                    <scrollactive :class="{ fixed : isSideMenuFixed, top: isTop, bottom: !isTop }" :modifyUrl="false" bezier-easing-value=".5,0,.35,1" :duration="600" :offset="200" class="side-nav">
                        <a href="#marketDevelopment" class="scrollactive-item">{{ $t("pooBear.sideTabs.marketDevelopment") }}</a>
                        <a href="#goTomarket" class="scrollactive-item">{{ $t("pooBear.sideTabs.goTomarket") }}</a>
                        <a href="#businessConsultion" class="scrollactive-item">{{ $t("pooBear.sideTabs.businessConsultion") }}</a>
                        <a href="#specialParties" class="scrollactive-item">{{ $t("pooBear.sideTabs.specialParties") }}</a>
                    </scrollactive>
                </div>
                <div class="content-holder">
                    <div id="marketDevelopment" class="side-scrol-section">
                        <marketDevelopment/>
                    </div>
                    <div id="goTomarket" class="side-scrol-section">
                        <goToMarket/>
                    </div>
                    <div id="businessConsultion" class="side-scrol-section">
                        <businessConsulting/>
                    </div>
                    <div id="specialParties" class="side-scrol-section">
                        <specialParties/>
                    </div>
                </div>
            </div>
            <successSection/>
            <stub/>
            <section class="call-to-action">
                <action 
                    :questionText="$t('actionQuestion')" 
                    :linkText="$t('actionBtnText')" linkHref="/services" />
            </section>
            <FOOTER/>
        </div>
    </main>
</template>
<script>
import navigation from './../components/navigation'
import HEADER from '../sections/poo-bear/header'
import brief from '../sections/poo-bear/brief'
import stub from './../sections/stub'
import marketDevelopment from '../sections/poo-bear/marketDevelopment'
import goToMarket from '../sections/poo-bear/gotToMarket'
import businessConsulting from '../sections/poo-bear/businessConsulting'
import specialParties from '../sections/poo-bear/specialParties'
import successSection from '../sections/poo-bear/success'
import action from './../components/action-block'
import FOOTER from './../sections/footer'
import cookiesPopup from './../components/cookies-popup';

export default {
    data() {
        return {
            isHeroBg: true,
            isFirstStubBg: false,
            isSideMenuFixed: false,
            isSecondStubBg: false,
            isTop: true,

            isWhiteNav: true,
            navElement: null,

            mobileWidth: 793,
            tabletWidth: 992
        }
    },
    methods: {
        bgTriggerSecond(evt, el, windowTop) {
            this.isHeroBg = this.getCoords(el).top >= windowTop;
            this.isSecondStubBg = this.getCoords(el).top <= windowTop;
        },
        sideMenuHandler(evt, el, windowTop) {

            if(!el) return;

            const topOffset = this.getCoords(el).top;
            const bottomOffset = this.getCoords(el).top + el.offsetHeight;
            const bottomWindow = windowTop + window.innerHeight;

            this.isSideMenuFixed = topOffset <= windowTop && bottomOffset >= bottomWindow;
            this.isTop = windowTop <= topOffset;
        },
        getCoords(elem) { 
            const box = elem.getBoundingClientRect();

            const body = document.body;
            const docEl = document.documentElement;

            const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

            const clientTop = docEl.clientTop || body.clientTop || 0;
            const clientLeft = docEl.clientLeft || body.clientLeft || 0;

            const top  = box.top +  scrollTop - clientTop;
            const left = box.left + scrollLeft - clientLeft;

            return { top: Math.round(top), left: Math.round(left) };
        },
        changeNavToWhite(evt, el, windowTop) {
            if(!el) return;

            if((el.offsetTop - this.navElement.offsetHeight) <= windowTop && (el.offsetTop + el.offsetHeight) >= windowTop) {
                this.isWhiteNav = true;
            }

            if(this.isMobile() || this.isTablet()) {
                this.isWhiteNav = true;
            }
        },
        changeNavToBlack(evt, el, windowTop) {
            if(!el) return;

            if((el.offsetTop - this.navElement.offsetHeight) <= windowTop && (el.offsetTop + el.offsetHeight) >= windowTop) {
                this.isWhiteNav = false;
            }

            if(this.isMobile() || this.isTablet()) {
                this.isWhiteNav = true;
            }
        },
        isMobile() {
            return this.getWindowWidth() <= this.mobileWidth;
        },
        isTablet() {
            return this.getWindowWidth() >= this.mobileWidth && this.getWindowWidth() <= this.tabletWidth;
        },
        getWindowWidth() {
            return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        }
    },
    components: {
        HEADER,
        brief,
        stub,
        marketDevelopment,
        navigation,
        goToMarket,
        businessConsulting,
        specialParties,
        successSection,
        action,
        FOOTER,
        cookiesPopup
    },
    mounted() {
        this.$http({ url: 'setting/7', method: 'GET'})
            .then((res) => {
                if(!+res.data.data.value) {
                    this.$router.push({ name: 'Home' });
                }
            });
        this.navElement = document.querySelector(".nav");
        
        if(this.isMobile() || this.isTablet()) {
            this.isWhiteNav = true;
        }
    }
}
</script>
<style lang="scss" scoped>
@import './../scss/app';

.side-scrol-section {
    height: auto;
    background-color: #fff;
}

.side-menu-wrapper {
    position: relative;
    display: flex;
}

.menu-holder {
    position: relative;
    top:0;
    left: 0;
    bottom: 0;
    width: 20vw;
    background-color: #EFA13F;
}

.content-holder {
    position: relative;
    width: 80vw;
}

.side-nav {
    position: absolute;
    left: 0;
    right:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 0 0 44px;

    a {
        display: block;
        margin: 0.7em 0;
        letter-spacing: 0.056em;
        text-align: left;
        text-transform: uppercase;
        width: 100%;
        font-family: $family-medium;
        font-size: 1.1vw;
        line-height: 1em;
        text-decoration: none;
        color: #fff;
        @include opacity(.6);

        &.is-active {
            @include opacity(1);
        }
    }

    &.top {
        top:0;
    }

    &.bottom {
        bottom:0;
    }

    &.fixed {
        position: fixed;
        z-index: 1;
        top:0;
        bottom: 0;
        right: 80vw;
    }
}

.call-to-action {
    position: relative;
    background-color: #1D1F1F;
}

@media (max-width: 992px) {
    .side-nav {
        padding: 0;
    }
    .menu-holder {
        width: 64px;
        flex-direction: column;
        justify-content: center;
        
        a {
            display: none;
            color: #ffffff;
            
        }

        a.is-active {
            position: relative;
            bottom: 60px;
            margin-bottom: -50%;
            display: flex;
            @include rotate(-90deg);
            white-space: nowrap;
            height: 120px;
            font-size: 17px;
        }
    }

    .content-holder {
        width: 100%;
    }
}

@media (max-width: 576px) {

    .side-scrol-section {
        height: auto;
        background-color: #fff;
    }

    .side-nav {
        display:none;
        padding-left: 25px;

        &.fixed {
            padding-left: 5px;
        }
    }
    
    .menu-holder {
        display:none;
        a.is-active {
            padding-left: 25px;
            height: 40px;
            font-size: 17px;
        }
    }
}
</style>