var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"pb-text-wrapper"},[_c('div',{staticClass:"content"},[_c('h3',[_vm._v(_vm._s(_vm.$t("pooBear.specialParties.title")))]),_c('p',[_vm._v(_vm._s(_vm.$t("pooBear.specialParties.text")))])]),_c('div',{staticClass:"image",on:{"mouseleave":function($event){return _vm.changePictureIndex(0)}}},[_c('div',{staticClass:"image-block",class:{
            'active': _vm.activeIndex == 1,
            'shrink': _vm.activeIndex != 0 && _vm.activeIndex != 1
        },on:{"mouseover":function($event){return _vm.changePictureIndex(1)}}},[_c('div',{staticClass:"image-block-inner",staticStyle:{"background-image":"url('images/poo/20190902_163134.jpg')"}},[_c('div',{staticClass:"image-block-text"},[_vm._v(_vm._s(_vm.$t('pooBear.specialParties.images.title1')))])])]),_c('div',{staticClass:"image-block",class:{
            'active': _vm.activeIndex == 2,
            'shrink': _vm.activeIndex != 0 && _vm.activeIndex != 2
        },on:{"mouseover":function($event){return _vm.changePictureIndex(2)}}},[_c('div',{staticClass:"image-block-inner",staticStyle:{"background-image":"url('images/poo/20190901_155850.jpg')"}},[_c('div',{staticClass:"image-block-text"},[_vm._v(_vm._s(_vm.$t('pooBear.specialParties.images.title2')))])])]),_c('div',{staticClass:"image-block",class:{
            'active': _vm.activeIndex == 3,
            'shrink': _vm.activeIndex != 0 && _vm.activeIndex != 3
        },on:{"mouseover":function($event){return _vm.changePictureIndex(3)}}},[_c('div',{staticClass:"image-block-inner",staticStyle:{"background-image":"url('images/poo/20190903_234351 (4).jpg')"}},[_c('div',{staticClass:"image-block-text"},[_vm._v(_vm._s(_vm.$t('pooBear.specialParties.images.title3')))])])]),_c('div',{staticClass:"image-block",class:{
            'active': _vm.activeIndex == 4,
            'shrink': _vm.activeIndex != 0 && _vm.activeIndex != 4
        },on:{"mouseover":function($event){return _vm.changePictureIndex(4)}}},[_c('div',{staticClass:"image-block-inner",staticStyle:{"background-image":"url('images/poo/20190905_152317 2.jpg')"}},[_c('div',{staticClass:"image-block-text"},[_vm._v(_vm._s(_vm.$t('pooBear.specialParties.images.title4')))])])])])]),_c('h2',{staticClass:"title-instagram-section"},[_vm._v(_vm._s(_vm.$t("pooBear.specialParties.instagramTitle")))]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"instagram-body"},[_c('iframe',{attrs:{"scrolling":"no","src":"./backgrounds/instagram-poo-bear.html"}})])}]

export { render, staticRenderFns }