<template>
    <div class="pic-continer-wrapper">
        <div class="pic-wrapper">
            <img :src="url">
        </div>
        <div class="pic-shadow" :style="{ 'top': shadowTop + '%', 'left': shadowLeft + '%' }"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShiftImg: false
        }
    },
    created() {
        window.addEventListener('scroll', this.onScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    },
    props: {
        url: String,
        shadowTop: {
            default: 6.5,
            type: Number
        },
        shadowLeft: {
            default: 8,
            type: Number
        }
    },
    methods: {
        onScroll() {
            const elements = document.getElementsByClassName("pic-continer-wrapper");
            const windowTop = window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset;

            for(let i = 0; i < elements.length; i++)
            {
                const el = elements[i];

                if(this.getCoords(el).top <= windowTop + window.innerHeight)
                {
                    el.classList.add("active");
                }
                else
                {
                    el.className = el.className.replace(/\bactive\b/g, "");
                }
            }
        },
        getCoords(elem) { 
            const box = elem.getBoundingClientRect();

            const body = document.body;
            const docEl = document.documentElement;

            const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

            const clientTop = docEl.clientTop || body.clientTop || 0;
            const clientLeft = docEl.clientLeft || body.clientLeft || 0;

            const top  = box.top +  scrollTop - clientTop;
            const left = box.left + scrollLeft - clientLeft;

            return { top: Math.round(top), left: Math.round(left) };
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../scss/app';

.pic-continer-wrapper {
    position: relative;
    z-index: 0;
}

.pic-shadow {    
    position: absolute;
    transform: scale(0.95);
    background: #191a1a;
    display: block;
    filter: blur(6px);
    @include opacity(0.18);
    width: 100%;
    height: 100%;
    z-index: -1;
    transition-delay: 250ms;
    transition: transform 1250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    will-change: transform;
}

.pic-wrapper {
    border-radius: 2px;
    height: inherit;
    width: inherit;
    overflow: hidden;

    img {
        display: block;
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
    }

    @include transition(all 1000ms);
    @include translate(20px, 10px);
}


.pic-continer-wrapper.active {
    .pic-wrapper {
        @include translate(0, 0);
    }

    .pic-shadow {
        transform: scale(1);
    }
}



</style>