<template>
    <div class="wrapper">
        <div class="go-to-market-wrapper">
            <div class="cel-image">
                <imageFrame :shadowTop="5" :shadowLeft="7" class="pic-frame" url="images/poo/IMG_20190511_122827_037.jpg" />
            </div>
            <div class="cel">
                <h2>{{ $t("pooBear.goTomarket.title") }}</h2>
                <p>{{ $t("pooBear.goTomarket.text") }}</p>
            </div>
        </div>
        <div class="songs-list">
            <h2>{{ $t("pooBear.goTomarket.items.title") }}</h2>
            <div class="row">
                <div class="item">
                    <div class="image">
                        <img src="images/poo/21843_300.jpg" alt="album">
                    </div>
                    <div class="summary">
                        <span class="year">2019</span>
                        <span class="song-name">Face My Fears</span>
                        <span class="artisit">Hikaru Utada & Skrillex</span>
                    </div>
                </div>
                <div class="item">
                    <div class="image">
                        <img src="images/poo/71R7Lx8aOTL._SS500_.jpg" alt="album">
                    </div>
                    <div class="summary">
                        <span class="year">2019</span>
                        <span class="song-name">Bad Guy (Remix)</span>
                        <span class="artisit">Billie Eilish & J. Bieber</span>
                    </div>
                </div>
                <div class="item">
                    <div class="image">
                        <img src="images/poo/One_Ok_Rock_–_Eye_of_the_Storm.png" alt="album">
                    </div>
                    <div class="summary">
                        <span class="year">2019</span>
                        <span class="song-name">Head High</span>
                        <span class="artisit">One Ok Rock</span>
                    </div>
                </div>
                <div class="item">
                    <div class="image">
                        <img src="images/poo/DJ_Khaled_-_Father_of_Asahd.png" alt="album">
                    </div>
                    <div class="summary">
                        <span class="year">2018</span>
                        <span class="song-name">Father of Asahd</span>
                        <span class="artisit">DJ Khaled</span>
                    </div>
                </div>
                <div class="item">
                    <div class="image">
                        <img src="images/poo/Mariah_Carey_-_Caution.png" alt="album">
                    </div>
                    <div class="summary">
                        <span class="year">2018</span>
                        <span class="song-name">The Distance</span>
                        <span class="artisit">Mariah Carey</span>
                    </div>
                </div>
                <div class="item">
                    <div class="image">
                        <img src="images/poo/Skrillex_Poo_Bear_Would_You_Ever.jpg" alt="album">
                    </div>
                    <div class="summary">
                        <span class="year">2017</span>
                        <span class="song-name">Would You Ever</span>
                        <span class="artisit">Skrillex</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import imageFrame from './../../components/shadowed-pic'
export default {
    components: {
        imageFrame
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.wrapper {
    padding: 10vw 10vw 5vw;
}

.go-to-market-wrapper {
    padding: 0 0 5vw 0;
    background-color: #fff;
    display: flex;
}

.cel-image {
    width:50%;
    padding-right: 10%;
    padding-top: 70px;

    .pic-frame {
        width: 100%;
        margin: auto;
    }
}

.cel {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 5vh; 

    h2 {
        width:100%;
        font-family: $family-bold;
        margin-bottom: 0.8em;
        font-size: 2.2vw;
        letter-spacing: 0.015em;
        line-height: 1em;
        font-weight: 400;
        color:#1D1F1F;
        font-weight: 600;
    }

    p {
        font-family: $family-light;
        color: #313333;
        font-size: 1.4vw;
        line-height: 1.56em;
    }
}

.row {
    display: flex;
    padding-bottom: 5vh;
}

.item {
    position: relative;
    display: flex;
    align-items: center;
    width: 33.33333333%;
    height: 100px;
    margin-bottom: 2.5vh;
}

.image {
    position: relative;
    height: 100px;
    width: 100px;
    overflow: hidden;

    img {
        height: 100%;
    }

    &::after, &::before {
        content: '';
        position: absolute;
        left:100%;
        background: #fff;
        height: 100px;
        width: 100px;
    }

    &::after {
        top: 0;
        @include skew(10deg, 0);
    }

    &::before {
        bottom: 0;
        @include skew(-10deg, 0);
    }
}

.summary {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 0 10px;
    span {
        font-family: $family-light;
        font-size: 1.2vw;
    }
}

.songs-list h2 {
    text-align: center;
    padding: 0 0 5vh;
    margin-bottom: 0.8em;
    @include font-size(21px);
    font-size: calc(21px + 3 * ((100vw - 375px) / 649));
    font-family: $family-bold;
    font-weight: 600;
}

.summary span.song-name {
    background-color: #EFA13F;
    @include font-size(19px);
    line-height: 34px;
    color: #fff;
    height: 30px;
    width: auto;
    padding: 0 14px;
    border-radius: 2px;
    font-family: $family-bold;
    font-weight: 600;
}

@media (max-width: 992px) {

    .wrapper {
        padding: 20vw 10vw 0;
    }

    .songs-list h2 {
       padding: 0 0 2.5vh; 
       text-align: center;
    }

    .item {
        width: 50%;
    }

    .go-to-market-wrapper {
        flex-direction: column-reverse;
    }

    .cel-image {
        width: 100%;
        padding-top: 5vw; 
        padding-right: 0;

        img {
            width: 100%;
        }
    }

    .cel {
        width: 100%;
        padding: 0;

        h2 {
            margin-bottom: 0.8em;
            @include font-size(21px);
            font-size: calc(21px + 3 * ((100vw - 375px) / 649));
        }

        p {
            font-family: $family-light;
            font-size: 1.4vw;
            line-height: 1.56em;
            will-change: transform;
            font-size: calc(16px + 2 * ((100vw - 375px) / 649));
        }
    }

    .summary {
        span {
            font-size: 2.2vw;
        }
    }
}

@media (max-width: 576px) { 
    .wrapper {
        padding: 15vw 5vw 10vw 5vw;
    }
    .go-to-market-wrapper {
        padding: 0 0 10vw 0;
    }

    .row {
        padding-bottom:0;
    }

    .item {
        width: 100%;
    }

    .summary {
        span {
            font-size: 4.2vw;
        }
        .song-name {
            font-size: 4.7vw;
        }
    }
}
</style>