<template>
    <section class="pb-branding-wrapper">
        <div class="content">
            <h3>{{ $t("pooBear.marketDevelopment.title") }}</h3>
            <p>{{ $t("pooBear.marketDevelopment.text") }}</p>
        </div>
        <div class="image">
            <imageFrame :shadowTop="5" :shadowLeft="7" class="pic-frame" url="images/poo/20191214_210646-min.jpg" />
        </div>
    </section>
</template>
<script>
import imageFrame from './../../components/shadowed-pic'
export default {
    components: {
        imageFrame
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.pb-branding-wrapper {
    padding: 10vw 10vw 0 10vw;
    background-color: #fff;
    display: flex;
}

.content {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
        width:100%;
        font-family: $family-bold;
        margin-bottom: 0.8em;
        font-size: 2.2vw;
        letter-spacing: 0.015em;
        line-height: 1em;
        font-weight: 600;
        color:#1D1F1F;
    }

    p {
        font-family: $family-light;
        color: #313333;
        font-size: 1.4vw;
        line-height: 1.56em;
    }
}

.image {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 45%;
    right: -5%;

    img {
        width: 80%;
    }
}

@media (max-width: 992px) {
    .pb-branding-wrapper {
        padding: 10vw 10vw 0 10vw;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        height: auto;
    } 

    .content {
        width: 100%;
        padding-bottom: 10vw;

        h3 {
            margin-bottom: 0.8em;
            @include font-size(23px);
            font-size: calc(23px + 4 * ((100vw - 375px) / 649));
        }

        p {
            font-family: $family-light;
            color: #313333;
            font-size: 1.4vw;
            line-height: 1.56em;
            will-change: transform;
            font-size: calc(16px + 2 * ((100vw - 375px) / 649));
        }
    }

    .image {
        width:100%;
        right:0;

        img {
            margin: auto;
            width: 70%;
        }
    }
}

@media (max-width: 576px) {
    .pb-branding-wrapper {
        padding: 15vw 5vw 10vw 5vw;
    }

    .content {
        padding-bottom: 10vw;
    }

    .image {
        width:100%;

        img {
            margin: auto;
            width: 100%;
        }
    }
}

</style>