<template>
    <section 
        class="stub-section" 
        v-scroll="scrolled" 
        :class="{ 
            'stub-bordered': isBorderShown,
            position: isPositionRelative
        }">
        <div class="border-stub-block" :style="{ 'border-color': color }"></div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            isBorderShown: false,
            loker: false,
            isPositionRelative: true
        }
    },
    props: {
        color: {
            default:"#1D1F1F",
            type: String
        }
    },
    methods: {
        scrolled(evt, el, windowTop) {
            this.isBorderShown = el.offsetTop - window.innerHeight < windowTop;

            if(!this.loker && this.isBorderShown) {
                setTimeout(() => {
                    this.isPositionRelative = false;
                }, 600);
                this.loker = true;
            } else if(!this.isBorderShown && this.loker) {
                this.loker = false;
                this.isPositionRelative = true;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../scss/app';

.stub-section {
    &.position {
        position: relative;
        z-index: 1;
        .border-stub-block {
            display: block;
        }
    }
    
    height: 100vh;
    overflow: hidden;

    .border-stub-block {
        display: none;
        z-index: 0;
        border: solid 0;
        border-width: 36px;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        @include transition(border-width 600ms ease);
    }

    &.stub-bordered .border-stub-block {
        border-width: 0;
        @include transition(border-width 600ms ease);
    }
}



@media (max-width: 992px) {
    .stub-section {
        height: 60vw;
    }
}

@media (max-width: 576px) {
    .stub-section {
        height: 100vw;
    }
}
</style>